import React from 'react';


function flip1(){
    let element = document.getElementById("flip-card-inner-1");
    let button = document.getElementById("button1");

    element.classList.toggle("flip-card-inner-back")
    if(element.classList.contains("flip-card-inner-back") === true){
        button.innerHTML = "X"
    }
    else{
        button.innerHTML = "Leer más"
    }
}

function flip2(){
    let element = document.getElementById("flip-card-inner-2");
    let button = document.getElementById("button2");
    
    element.classList.toggle("flip-card-inner-back")
    if(element.classList.contains("flip-card-inner-back") === true){
        button.innerHTML = "X"
    }
    else{
        button.innerHTML = "Leer más"
    }
}

function flip3(){
    let element = document.getElementById("flip-card-inner-3");
    let button = document.getElementById("button3");
    
    element.classList.toggle("flip-card-inner-back")
    if(element.classList.contains("flip-card-inner-back") === true){
        button.innerHTML = "X"
    }
    else{
        button.innerHTML = "Leer más"
    }
}

function flip4(){
    let element = document.getElementById("flip-card-inner-4");
    let button = document.getElementById("button4");
    
    element.classList.toggle("flip-card-inner-back")
    if(element.classList.contains("flip-card-inner-back") === true){
        button.innerHTML = "X"
    }
    else{
        button.innerHTML = "Leer más"
    }
}

function flip5(){
    let element = document.getElementById("flip-card-inner-5");
    let button = document.getElementById("button5");
    
    element.classList.toggle("flip-card-inner-back")
    if(element.classList.contains("flip-card-inner-back") === true){
        button.innerHTML = "X"
    }
    else{
        button.innerHTML = "Leer más"
    }
}

function Services () {



    return(
            <div id="services" className="services">
                <div className="cards">
                    <div className="flip-card">
                        <div id="flip-card-inner-1" className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src="https://firebasestorage.googleapis.com/v0/b/urbina-casas.appspot.com/o/proyectos.svg?alt=media&token=5ae193d8-fd50-444c-b77a-64bb929900da" alt="Proyectos habitacionales" Style="width:100%"/>
                                <h2 className="s1">Proyectos Personalizados</h2>
                            </div>
                            <div className="flip-card-back">
                                <b className="card-title">Proyectos Personalizados</b>
                                <br></br>
                                <p className="card-base-text">Entendemos que tus necesidades de vivienda y espacios son variadas, por este motivo hemos flexibilizado nuestros proyectos con la intención de que puedas diseñar tus propios espacios ya sea sobre nuestros modelos o entregándonos tus propias ideas para llevarlas a cabo.</p>
                            </div>
                        </div>    
                        <button onClick={flip1} id="button1" >Leer más</button>
                    </div>

                    <div className="flip-card">
                        <div id="flip-card-inner-2" className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src="https://firebasestorage.googleapis.com/v0/b/urbina-casas.appspot.com/o/fosa.svg?alt=media&token=7afa3dc3-367c-478c-839f-261d18583e7b" alt="Instalacion de fosas septicas" Style="width:100%"/>
                                <h2 className="s2">Instalación de fosas sépticas</h2>
                                
                            </div>
                            <div className="flip-card-back">
                                <b className="card-title">Instalación de fosas sépticas</b>
                                <p className="card-base-text">Ofrecemos el servicio de instalación de fosa séptica para los terrenos que no cuentan con alcantarillado, además de la asesoría para el proyecto y así obtener la aprobación sanitaria</p>
                            </div>
                        </div>    
                        <button onClick={flip2} id="button2">Leer más</button>
                    </div>

                    <div  className="flip-card">
                        <div id="flip-card-inner-3" className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src="https://firebasestorage.googleapis.com/v0/b/urbina-casas.appspot.com/o/carpeta_mun.svg?alt=media&token=7d751ff5-ef75-47f8-bfe4-c27f724750b1" alt="Carpeta municipal" Style="width:100%"/>
                                <h2 className="s3">Preparación de carpeta municipal</h2>
                                
                            </div>
                            <div className="flip-card-back">
                                <b className="card-title">Preparación de carpeta municipal</b>
                                <p className="card-base-text">Te asesoramos con la documentación y planimetría que necesitas para obtener el permiso de construcción de tu proyecto</p>
                            </div>
                        </div>    
                        <button onClick={flip3} id="button3">Leer más</button>
                    </div>

                    <div  className="flip-card">
                        <div id="flip-card-inner-4" className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src="https://firebasestorage.googleapis.com/v0/b/urbina-casas.appspot.com/o/visita.svg?alt=media&token=1dcfb66c-8b02-4082-85fc-9bfac06993e6" alt="Cotizar casas" Style="width:100%"/>
                                <h2 className="s4">Visitas a terreno</h2>
                                
                            </div>
                            <div className="flip-card-back">
                            <b className="card-title">Visitas a terreno</b>
                                <p className="card-base-text">Antes de llevar a cabo un proyecto, siempre es necesario saber con antelación toda la información que sea necesaria para llevar a buen término dicho proyecto, por ejemplo, cuales son las exigencias según la normativa del plano regulador del sector o donde corresponde instalar la fosa segun las caracteristas del terreno. Te ayudamos en esta materia para evitar gastos innecesarios. </p>
                            </div>
                        </div>   
                        <button onClick={flip4} id="button4">Leer más</button> 
                    </div>

                    <div  className="flip-card">
                        <div id="flip-card-inner-5" className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src="https://firebasestorage.googleapis.com/v0/b/urbina-casas.appspot.com/o/asesoria.svg?alt=media&token=adcb4a26-cfdc-4632-8036-be4720f7ad8b" alt="Asesoria proyectos habitacionales" Style="width:100%"/>
                                <h2 className="s5">Asesoría general</h2>
                                
                            </div>
                            <div className="flip-card-back">
                            <b className="card-title">Asesoría general</b>
                                <p className="card-base-text">Nuestra atención siempre será personalizada, prestando toda la asesoría que se requiere para diseñar, desarrollar, implementar y concretar tu proyecto aportando nuestra vasta experiencia y conocimiento en nuestro rubro</p>
                            </div>
                        </div>  
                        <button onClick={flip5} id="button5">Leer más</button>  
                    </div>
                </div>  
            </div> 
    )
}

export default Services;

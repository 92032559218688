import React, { useEffect } from 'react';
import { useState } from 'react';
import getCollection from '../hooks/GetCollection'
import { motion } from 'framer-motion';
import { Fragment } from 'react';

const Modal = ({ selectedImg, setSelectedImg, selectedColl, setSelectedColl }) => {
    const { collDocs, setCollDocs, collSize } = getCollection(selectedColl);
    const [ currentIndex, setCurrentIndex ] = useState( 0 );
    const [ arrayLength, setArrayLength] = useState ( 0 );
    
    const handleClick = (e) =>{
        if(e.target.classList.contains('close')){
            setSelectedImg( null );
            setSelectedColl( null );
            setCurrentIndex( 0 );
            setArrayLength( 0 );
            setCollDocs(null);
            document.body.style.overflow = "auto";

        }
    }

    

    useEffect(()=>{
        if(currentIndex<0 || currentIndex>arrayLength-1){
            setCurrentIndex(0);
        }else{
            setSelectedImg(collDocs[currentIndex].url);
        }

        if(currentIndex > 0){
            document.getElementById("middle").classList.add("notVisible");
        }
        else{
            document.getElementById("middle").classList.remove("notVisible");
        }
    },[currentIndex])


    useEffect(()=>{
        setArrayLength( collSize );
    },[collSize])


    document.body.style.overflow = "hidden";
    
    

    return(
        <Fragment>
            <motion.div className="backdrop" 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
            >   
                <div className="leftArrow">
                    <i className="fas fa-chevron-left" onClick={()=>setCurrentIndex(currentIndex-1)} id="arrow-prev"></i>
                </div>

                <div className="imgContainer">
                    <div className="img">
                        <motion.img src={ selectedImg } alt="enlarged pic" 
                            initial={{ y: "-100vh" }}
                            animate={{ y: "0vh" }}
                            transition={{ delay: 0.6 }}
                        />
                    </div>
                </div>


                <div className="rightSide">
                    <div className="closeModal">
                        <i className="fas fa-times close" id="close" onClick={handleClick}></i>
                    </div>
                    <div className="rightArrow">
                        <i className="fas fa-chevron-right" onClick={()=>setCurrentIndex(currentIndex+1)} id="arrow-next"></i>
                    </div>
                </div>

            </motion.div>
        </Fragment>
    )
}

export default Modal;

import React, { useState } from 'react';


const MenuItems = [ 
  {
    title: 'Quiénes somos',
    action: '#aboutUs',
    className: 'nav-links'
  },
  {
    title: 'Servicios',
    action: '#services',
    className: 'nav-links'
  },
  {
    title: 'Galería',
    action: '#gallery',
    className: 'nav-links'
  },
  {
    title: 'Contacto',
    action: '#contact',
    className: 'nav-links'
  }
]

const NavBar = () =>{
  const [state, setState] = useState(false)

 const handleClick = () => {
    setState(!state);
  }

 const closeNav = () =>{
    document.getElementById('navBar').classList.remove('active');
    document.getElementById('menuIcon').classList.remove('fa-times');
    document.getElementById('menuIcon').classList.add('fa-bars');
    document.body.style.overflow = "auto";
  }

  document.body.style.overflow = state ? "hidden" : "auto";


  
    return (
      <nav className="NavbarItems" id="navBarItems">
        
        <div className="menu-icon" onClick={handleClick}>
          <i className={ state ? 'fas fa-times' : 'fas fa-bars'} id="menuIcon"></i>
        </div>
        <ul className={ state ? 'nav-menu active' : 'nav-menu'} id="navBar">
          {MenuItems.map((item,index) => {
            return(
              <li key={index}>
                <a className={item.className} onClick={()=> closeNav()} href={item.action}>
                  {item.title}
                </a>
              </li>
            )
          })}
        </ul>

      </nav>
    )
  }


export default NavBar;


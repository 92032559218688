import React, { useState } from 'react';
import axios from 'axios';


const Contact = () => {
  const [data, setData] = useState({nombre:'', telefono:'', email:'', lugar:'', mensaje:'', sent: false, buttonText: 'Enviar', err:''})

  const handleChange = (e) => {
    const {name, value} = e.target
      setData({
        ...data,
        [name]: value
      })
  }

  const resetForm = () => {
    setData({
        nombre: '',
        telefono:'',
        email: '',
        lugar: '',
        mensaje:  '',
        sent: false,
        buttonText: 'Enviar',
        err: ''
    });
}

  const formSubmit = (e) =>{
    e.preventDefault()
    let re = /^[^\s@]+@[^\s@]+$/;

    if(data.nombre && data.lugar && re.test(data.email) && data.telefono && data.mensaje){
      setData({
        ...data,
        buttonText:'Enviando...'
      })
  
      axios.post('/api/sendmail', data)
      .then(res =>{
        if(res.data.result !=='success') {
          setData({
            ...data,
            buttonText: 'Error al enviar',
            sent: false,
            err: 'fallo'
          })
          setTimeout(()=>{
            resetForm()
          },6000)
        } else {
          setData({
            ...data,
            sent: true,
            buttonText: 'Enviado',
            err: 'exitoso'
          })
          setTimeout(()=>{
            resetForm()
          }, 6000)
        }
      }).catch((err)=>{
        setData({
          ...data,
          buttonText: 'Error al enviar',
          err: 'fallo'
        })
      })
    } else{
      alert("Por favor rellene todos los datos del formulario.")
    }
  }

  return (
    <div className="contactForm" id="contact">
      <div className="inner">
        <div className="panel panel-left">
          <div className="panel-content">
            <div className="titleContainer"><h2>Contacto</h2><span className="subtitle">Cotiza tu proyecto con nosotros</span></div>
          </div>
        </div>
        <div className="panel panel-right">
          <div className="panel-content">
            <form className="form">
              <div className="group">
                <input type="text" name="nombre" autoComplete="off" value={data.nombre} onChange={handleChange}/>
                <span className="highlight"></span>
                <label>Nombre</label>
              </div>
              <div className="group">
                <input type="tel" name="telefono" autoComplete="off" value={data.telefono} onChange={handleChange}/>
                <span className="highlight"></span>
                <label>Teléfono</label>
              </div>
              <div className="group">
                <input type="email" name="email" autoComplete="off" value={data.email} onChange={handleChange}/>
                <span className="highlight"></span>
                <label>Email</label>
              </div>
              <div className="group">
                <input type="text" name="lugar" autoComplete="off" value={data.lugar} onChange={handleChange}/>
                <span className="highlight"></span>
                <label>Lugar de construcción</label>
              </div>
              <div className="group">
                <textarea name="mensaje" placeholder="Mensaje" rows="13" cols="20" autoComplete="off" value={data.mensaje} onChange={handleChange}/>
              </div>
              <button className="submit" onClick={formSubmit}>{data.buttonText}</button>
              
            </form>
            <div id="toast" className="toast"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact;

import React from 'react';
import useOnScreen from '../hooks/isOnScreen';
import {useRef} from 'react';

function getWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}

function addClass() {
  if(getWidth() > 1024){
    var element = document.getElementById("aboutUsText");
    element.classList.add("animate__animated","animate__fadeInUp","animate__slower");
  
    var element2 = document.getElementById("aboutUsImg");
    element2.classList.add("animate__animated","animate__fadeInRight","animate__slower");
  }
}


const AboutUs = () => {

  const ref = useRef();
  const isVisible = useOnScreen(ref);

  return (
    <div className="aboutUs" id="aboutUs">
    <div className="aboutUsText" ref={ref} id="aboutUsText">
      {isVisible && addClass()}
      <h2>¿Quiénes somos?</h2>
      <span id="p0">
        Desde 1945, en CASAS URBINA hemos mercado presencia en el mercado
        nacional, con nuestra vasta experiencia en el <h1>desarrollo, diseño y construcción
        de proyectos habitacionales</h1>, cumpliendo fielmente con nuestro
        compromiso de aportar nuestro profesionalismo en el desarrollo de
        cada obra y que nuestros clientes queden satisfechos con el resultado de
        nuestro trabajo.
      </span>
      <span id="p1">
        Entendemos la importancia de contar con la asesoría arquitectónica y
        técnica al momento de diseñar los espacios y elegir la
        materialidad de su construcción ofreciendo la mejor relación precio calidad,
        es por eso, que nuestras alternativas de construcción son variadas ya sea
        con estructura en madera, acero galvanizado o panel SIP y revestimientos
        exteriores como vinyl siding, siding fibrocemento, enchapes o madera.
        Las construcciones con este tipo de materiales resultan perfectas para
        vivir, con hermosos y modernos diseños, funcionales, más económicas,
        menor tiempo de construcción, fáciles de ampliar, y además por la naturaleza de su estructura son
        antisísmicas.
      </span>
    </div>
    <div className="aboutUsImg" id="aboutUsImg">
      <img src="https://firebasestorage.googleapis.com/v0/b/urbina-casas.appspot.com/o/about.jpg?alt=media&token=9c8378f1-e247-4c9f-9020-cc5beb67d51c" alt="imgAbout"></img>
    </div>
    </div>
  )
}

export default AboutUs;

import { useState, useEffect } from 'react';
import { projectFirestore } from '../firebase/config';

const GetCollection = (coverCollection) =>{
    const [collDocs, setCollDocs] = useState([]);
    const [collSize, setCollSize] = useState(null);

    useEffect(() => {

        const qry = projectFirestore.collection('imagenes').where('collection', '==', coverCollection);

        const collSet = qry.onSnapshot((snap) =>{
            let collDocuments = [];
            snap.forEach(collDoc =>{
                collDocuments.push({...collDoc.data(), id:collDoc.id})
            });
            const sortedCollSet = collDocuments.sort((a,b) => a.createdAt - b.createdAt )
            setCollDocs(sortedCollSet);
        });

        return () => collSet;
        
    },[coverCollection],[]);

    useEffect(()=>{

        const qry = projectFirestore.collection('colecciones').where('collection','==', coverCollection).get();
        qry.then((snap)=>{
            const data = snap.docs[0].data().size;
            setCollSize(data);
        })

    },[coverCollection], [])
    
    return { collDocs, setCollDocs, collSize };
}

export default GetCollection;

import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/analytics';

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyAdCG22lJOoLc5lQarguDlZrwDevlw96KE",
    authDomain: "urbina-casas.firebaseapp.com",
    databaseURL: "https://urbina-casas.firebaseio.com",
    projectId: "urbina-casas",
    storageBucket: "urbina-casas.appspot.com",
    messagingSenderId: "970174001740",
    appId: "1:970174001740:web:ede285d28806ec9daefae9",
    measurementId: "G-H4636JZ055"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

  const projectStorage = firebase.storage();
  const projectFirestore = firebase.firestore();
  const projectAnalytics = firebase.analytics();
  const timestamp = firebase.firestore.FieldValue.serverTimestamp;

  export { projectStorage, projectFirestore, projectAnalytics, timestamp };
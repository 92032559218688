import { useEffect, useState } from "react";

export default function useOnScreen(ref){

    const [isIntersecting, setIntersecting] = useState(false);

    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))

    useEffect(()=> {
        observer.observe(ref.current)
        //remove the observer
        return () => {observer.disconnect()}
    }, [])

    return isIntersecting
}

import React, { useState } from 'react';

import ImageGrid from './imageGrid';
import Modal from './Modal';

function Gallery (){
    const [selectedImg, setSelectedImg] = useState(null);
    const [selectedColl, setSelectedColl] = useState(null);

    return(
        <div className="gallery" id="gallery">

        <ImageGrid setSelectedImg={setSelectedImg} setSelectedColl={setSelectedColl} />

        { selectedImg && <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} selectedColl={selectedColl} setSelectedColl={setSelectedColl} />}

        </div>
    )
}

export default Gallery;
import React from 'react';
import {Parallax} from 'react-parallax'; 

const introImg = "https://firebasestorage.googleapis.com/v0/b/urbina-casas.appspot.com/o/parallax.jpg?alt=media&token=629c8eea-b9d6-4fd9-a276-301fc4765a9a";


const Intro = () => {
    return(
        <Parallax bgImage={ introImg } strength={ 0 } blur={{ min: -5, max: 6 }}>
            <div className="intro">
                <div className="introPhoto"><img className="introLogo" alt="navbar-logo" src="https://firebasestorage.googleapis.com/v0/b/urbina-casas.appspot.com/o/logo.png?alt=media&token=56059d19-fe33-4718-a532-19a04c3342f3" /></div>
            </div>  
        </Parallax>
    )
}

export default Intro;

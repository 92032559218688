import { useState, useEffect } from 'react';
import { projectFirestore } from '../firebase/config';

const useFirestore = () => {
    const [docs, setDocs] = useState([]);

    useEffect(() => {

        const query = projectFirestore.collection('imagenes').where('cover','==',true);

        const set = query.onSnapshot((snap)=>{
            let documents = [];
            snap.forEach(doc => {
                documents.push({...doc.data(), id: doc.id})
            });
            const sortedDocuments = documents.sort((a,b) => b.createdAt - a.createdAt )
            setDocs(sortedDocuments);
        });
      
        return () => set;
        
    },[])

    return { docs };
}

export default useFirestore;
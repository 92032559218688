import React from 'react';

const Footer = () => {
  return (
    <div className="footer" id="footer">
      <div className="mapouter"><div className="gmap_canvas"><iframe title="map"id="gmap_canvas" src="https://maps.google.com/maps?q=casas%20urbina&t=&z=17&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe></div><style></style></div>
      <div className="footer-right-container">
      <div className="footer-right-text">
        <i className="fa fa-whatsapp fa-lg icon"></i>&nbsp;&nbsp;&nbsp;
        <span>WhatsApp al +56 9 9966 8776</span>
        <br></br>
        <br></br>
        <i className="fa fa-map-marker fa-lg icon"></i>&nbsp;&nbsp;&nbsp;
        <span>Regina Gálvez #285, San Bernardo, Región Metropolitana</span>
        <br></br>
        <br></br>
      </div>
      </div>
    </div>
  )
}

export default Footer;

import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AboutUs from './comps/AboutUs';
import Gallery from './comps/Gallery'
import Contact from './comps/Contact';
import Footer from './comps/Footer';
import NavBar from './comps/NavBar';
import Intro from './comps/Intro';
import Services from './comps/Services';
import './animate.css'


function App() {

  return (
   
    <BrowserRouter>
     <Redirect to="/constructora-casas"/>
      <Switch>
        <Route path="/constructora-casas">
          <div className="App">
            <NavBar />
            <div className="Content">
              <Intro />
              <Services/>
              <Gallery/>
              <AboutUs />
              <Contact/>
            </div>
            <Footer />
          </div>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;

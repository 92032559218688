import React from 'react';
import useFirestore from '../hooks/useFirestore';
import { motion } from 'framer-motion';

const ImageGrid = ({ setSelectedImg, setSelectedColl }) => {
    const { docs } = useFirestore();

    function setter(url, collection){
        setSelectedImg(url);
        setSelectedColl(collection);
    }

    return(
        <div className="img-grid">
            { docs && docs.map(doc => (
                <motion.div className="img-wrap" key={doc.id}
                layout
                whileHover={{opacity : 1}}
                onClick={()=> setter(doc.url, doc.collection)}>
                    <motion.img src={doc.url} alt="construccion casa veraneo"
                     initial={{ opacity: 0 }}
                     animate={{ opacity: 1 }}
                     transition={{ delay: 1 }}
                     />
                    <div className="middle" id="middle">
                    <div className="wrap-title">{doc.title}</div>
                    <div className="wrap-text">{doc.description}</div>
                    </div>
                </motion.div>
                
                
            ))}
        </div>
    )
}

export default ImageGrid